:root {
	--default-base: #d2814a;
	--default-shade: #b97241;
	--default-tint: #d78e5c;
	--default-light: #f5ebd7;
	--default-text: #FFFFFF;
	--default-low-opacity: #d2814a66;
	--ion-background-color: #00000000;
	--ion-toolbar-background: #00000000;
	// --default-base: #ffffff;
	// --default-shade: #b97241;
	// --default-tint: #d78e5c;
	// --default-light: #f5ebd7;
	// --default-text: #FFFFFF;
	// --default-low-opacity: #d2814a66;

	// --ion-color-primary: var(--default-base);
	// --ion-color-primary-rgb: 210,129,74;
	// --ion-color-primary-contrast: #000000;
	// --ion-color-primary-contrast-rgb: 0,0,0;
	// --ion-color-primary-shade: var(--default-shade);
	// --ion-color-primary-tint: var(--default-tint);
	// --ion-color-primary-light: var(--default-light);
	// --ion-color-primary-text: var(--default-text);
	// --ion-color-primary-low-opacity: var(--default-low-opacity);

	// --ion-color-success: #2dd36f;
	// --ion-color-success-rgb: 45,211,111;
	// --ion-color-success-contrast: #000000;
	// --ion-color-success-contrast-rgb: 0,0,0;
	// --ion-color-success-shade: #28ba62;
	// --ion-color-success-tint: #42d77d;

	// --ion-color-warning: #ffc409;
	// --ion-color-warning-rgb: 255,196,9;
	// --ion-color-warning-contrast: #000000;
	// --ion-color-warning-contrast-rgb: 0,0,0;
	// --ion-color-warning-shade: #e0ac08;
	// --ion-color-warning-tint: #ffca22;

	// // --ion-color-danger: #eb445a;
	// --ion-color-danger-rgb: 235,68,90;
	// --ion-color-danger-contrast: #ffffff;
	// --ion-color-danger-contrast-rgb: 255,255,255;
	// --ion-color-danger-shade: #cf3c4f;
	// --ion-color-danger-tint: #ed576b;

	// --ion-color-medium: #92949c;
	// --ion-color-medium-rgb: 146,148,156;
	// --ion-color-medium-contrast: #000000;
	// --ion-color-medium-contrast-rgb: 0,0,0;
	// --ion-color-medium-shade: #808289;
	// --ion-color-medium-tint: #9d9fa6;

	// --ion-color-light: #F1F5F9;
	// --ion-color-light-rgb: 241,245,249;
	// --ion-color-light-contrast: #000000;
	// --ion-color-light-contrast-rgb: 0,0,0;
	// --ion-color-light-shade: #d4d8db;
	// --ion-color-light-tint: #f2f6fa;

	--ion-color-background-content: #F1F5F9;
	--ion-color-background-navbar: #0E172A;

	/* Custom CLL App Colors */
	--ion-color-danger: #ee2f53;
  --ion-color-success: #38a169;

}
:host {
	--background: var(--transparent)  !important;
/* Custom CLL App Colors */
  --ion-color-danger: #ee2f53;

  --ion-color-success: #38a169;
}
